.alert-error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

.table tbody tr.error > td {
  background-color: #f2dede;
}

@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30%, 70% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.1);
  }

  20% {
    transform: scale(1);
  }
}

.dropzone, .dropzone * {
  box-sizing: border-box;
}

.dropzone {
  background: #fff;
  border: 1px dashed #ccc;
  border-radius: 4px;
  min-height: 150px;
  padding: 20px;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: .5;
}

.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}

.dropzone .dz-preview {
  vertical-align: top;
  min-height: 100px;
  margin: 16px;
  display: inline-block;
  position: relative;
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  background: linear-gradient(#eee, #ddd);
  border-radius: 20px;
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
  background: #fff;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.dropzone .dz-preview .dz-remove {
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 14px;
  display: block;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  opacity: 0;
  text-align: center;
  color: #000000e6;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  font-size: 13px;
  line-height: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  background-color: #fffc;
  border: 1px solid #c8c8c8cc;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid #0000;
}

.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
  background-color: #fff6;
  border-radius: 3px;
  padding: 0 .4em;
}

.dropzone .dz-preview:hover .dz-image img {
  filter: blur(8px);
  transform: scale(1.05);
}

.dropzone .dz-preview .dz-image {
  z-index: 10;
  border-radius: 20px;
  width: 120px;
  height: 120px;
  display: block;
  position: relative;
  overflow: hidden;
}

.dropzone .dz-preview .dz-image img {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  -ms-animation: passing-through 3s cubic-bezier(.77, 0, .175, 1);
  animation: 3s cubic-bezier(.77, 0, .175, 1) passing-through;
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -ms-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
  animation: 3s cubic-bezier(.77, 0, .175, 1) slide-in;
}

.dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  margin-top: -27px;
  margin-left: -27px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
  width: 54px;
  height: 54px;
  display: block;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -o-transition: all .2s linear;
  transition: all .2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -o-transition: opacity .4s ease-in;
  transition: opacity .4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -ms-animation: pulse 6s ease infinite;
  animation: 6s infinite pulse;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  background: #ffffffe6;
  border-radius: 8px;
  width: 80px;
  height: 16px;
  margin-top: -8px;
  margin-left: -40px;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  -webkit-transform: scale(1);
}

.dropzone .dz-preview .dz-progress .dz-upload {
  -o-transition: width .3s ease-in-out;
  background: linear-gradient(#666, #444);
  width: 0;
  transition: width .3s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  opacity: 0;
  -o-transition: opacity .3s ease;
  color: #fff;
  background: linear-gradient(#be2626, #a92222);
  border-radius: 8px;
  width: 140px;
  padding: .5em 1.2em;
  font-size: 13px;
  transition: opacity .3s;
  display: none;
  position: absolute;
  top: 130px;
  left: -10px;
}

.dropzone .dz-preview .dz-error-message:after {
  content: "";
  border-bottom: 6px solid #be2626;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: -6px;
  left: 64px;
}

[class*="hint--"] {
  display: inline-block;
  position: relative;
}

[class*="hint--"]:before, [class*="hint--"]:after {
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  transition: all .3s;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

[class*="hint--"]:hover:before, [class*="hint--"]:hover:after {
  visibility: visible;
  opacity: 1;
  transition-delay: .1s;
}

[class*="hint--"]:before {
  content: "";
  z-index: 1000001;
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

[class*="hint--"]:after {
  color: #fff;
  white-space: nowrap;
  background: #383838;
  padding: 8px 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 12px;
}

[class*="hint--"][aria-label]:after {
  content: attr(aria-label);
}

[class*="hint--"][data-hint]:after {
  content: attr(data-hint);
}

[aria-label=""]:before, [aria-label=""]:after, [data-hint=""]:before, [data-hint=""]:after {
  display: none !important;
}

.hint--top-left:before, .hint--top-right:before, .hint--top:before {
  border-top-color: #383838;
}

.hint--bottom-left:before, .hint--bottom-right:before, .hint--bottom:before {
  border-bottom-color: #383838;
}

.hint--left:before {
  border-left-color: #383838;
}

.hint--right:before {
  border-right-color: #383838;
}

.hint--top:before {
  margin-bottom: -11px;
}

.hint--top:before, .hint--top:after {
  bottom: 100%;
  left: 50%;
}

.hint--top:before {
  left: calc(50% - 6px);
}

.hint--top:after {
  transform: translateX(-50%);
}

.hint--top:hover:before {
  transform: translateY(-8px);
}

.hint--top:hover:after {
  transform: translateX(-50%)translateY(-8px);
}

.hint--bottom:before {
  margin-top: -11px;
}

.hint--bottom:before, .hint--bottom:after {
  top: 100%;
  left: 50%;
}

.hint--bottom:before {
  left: calc(50% - 6px);
}

.hint--bottom:after {
  transform: translateX(-50%);
}

.hint--bottom:hover:before {
  transform: translateY(8px);
}

.hint--bottom:hover:after {
  transform: translateX(-50%)translateY(8px);
}

.hint--right:before {
  margin-bottom: -6px;
  margin-left: -11px;
}

.hint--right:after {
  margin-bottom: -14px;
}

.hint--right:before, .hint--right:after {
  bottom: 50%;
  left: 100%;
}

.hint--right:hover:before, .hint--right:hover:after {
  transform: translateX(8px);
}

.hint--left:before {
  margin-bottom: -6px;
  margin-right: -11px;
}

.hint--left:after {
  margin-bottom: -14px;
}

.hint--left:before, .hint--left:after {
  bottom: 50%;
  right: 100%;
}

.hint--left:hover:before, .hint--left:hover:after {
  transform: translateX(-8px);
}

.hint--top-left:before {
  margin-bottom: -11px;
}

.hint--top-left:before, .hint--top-left:after {
  bottom: 100%;
  left: 50%;
}

.hint--top-left:before {
  left: calc(50% - 6px);
}

.hint--top-left:after {
  margin-left: 12px;
  transform: translateX(-100%);
}

.hint--top-left:hover:before {
  transform: translateY(-8px);
}

.hint--top-left:hover:after {
  transform: translateX(-100%)translateY(-8px);
}

.hint--top-right:before {
  margin-bottom: -11px;
}

.hint--top-right:before, .hint--top-right:after {
  bottom: 100%;
  left: 50%;
}

.hint--top-right:before {
  left: calc(50% - 6px);
}

.hint--top-right:after {
  margin-left: -12px;
  transform: translateX(0);
}

.hint--top-right:hover:before, .hint--top-right:hover:after {
  transform: translateY(-8px);
}

.hint--bottom-left:before {
  margin-top: -11px;
}

.hint--bottom-left:before, .hint--bottom-left:after {
  top: 100%;
  left: 50%;
}

.hint--bottom-left:before {
  left: calc(50% - 6px);
}

.hint--bottom-left:after {
  margin-left: 12px;
  transform: translateX(-100%);
}

.hint--bottom-left:hover:before {
  transform: translateY(8px);
}

.hint--bottom-left:hover:after {
  transform: translateX(-100%)translateY(8px);
}

.hint--bottom-right:before {
  margin-top: -11px;
}

.hint--bottom-right:before, .hint--bottom-right:after {
  top: 100%;
  left: 50%;
}

.hint--bottom-right:before {
  left: calc(50% - 6px);
}

.hint--bottom-right:after {
  margin-left: -12px;
  transform: translateX(0);
}

.hint--bottom-right:hover:before, .hint--bottom-right:hover:after {
  transform: translateY(8px);
}

.hint--small:after, .hint--medium:after, .hint--large:after {
  white-space: normal;
  line-height: 1.4em;
}

.hint--small:after {
  width: 80px;
}

.hint--medium:after {
  width: 150px;
}

.hint--large:after {
  width: 300px;
}

[class*="hint--"]:after {
  text-shadow: 0 -1px #000;
  box-shadow: 4px 4px 8px #0000004d;
}

.hint--error:after {
  text-shadow: 0 -1px #592726;
  background-color: #b34e4d;
}

.hint--error.hint--top-left:before, .hint--error.hint--top-right:before, .hint--error.hint--top:before {
  border-top-color: #b34e4d;
}

.hint--error.hint--bottom-left:before, .hint--error.hint--bottom-right:before, .hint--error.hint--bottom:before {
  border-bottom-color: #b34e4d;
}

.hint--error.hint--left:before {
  border-left-color: #b34e4d;
}

.hint--error.hint--right:before {
  border-right-color: #b34e4d;
}

.hint--warning:after {
  text-shadow: 0 -1px #6c5328;
  background-color: #c09854;
}

.hint--warning.hint--top-left:before, .hint--warning.hint--top-right:before, .hint--warning.hint--top:before {
  border-top-color: #c09854;
}

.hint--warning.hint--bottom-left:before, .hint--warning.hint--bottom-right:before, .hint--warning.hint--bottom:before {
  border-bottom-color: #c09854;
}

.hint--warning.hint--left:before {
  border-left-color: #c09854;
}

.hint--warning.hint--right:before {
  border-right-color: #c09854;
}

.hint--info:after {
  text-shadow: 0 -1px #1a3c4d;
  background-color: #3986ac;
}

.hint--info.hint--top-left:before, .hint--info.hint--top-right:before, .hint--info.hint--top:before {
  border-top-color: #3986ac;
}

.hint--info.hint--bottom-left:before, .hint--info.hint--bottom-right:before, .hint--info.hint--bottom:before {
  border-bottom-color: #3986ac;
}

.hint--info.hint--left:before {
  border-left-color: #3986ac;
}

.hint--info.hint--right:before {
  border-right-color: #3986ac;
}

.hint--success:after {
  text-shadow: 0 -1px #1a321a;
  background-color: #458746;
}

.hint--success.hint--top-left:before, .hint--success.hint--top-right:before, .hint--success.hint--top:before {
  border-top-color: #458746;
}

.hint--success.hint--bottom-left:before, .hint--success.hint--bottom-right:before, .hint--success.hint--bottom:before {
  border-bottom-color: #458746;
}

.hint--success.hint--left:before {
  border-left-color: #458746;
}

.hint--success.hint--right:before {
  border-right-color: #458746;
}

.hint--always:after, .hint--always:before {
  opacity: 1;
  visibility: visible;
}

.hint--always.hint--top:before {
  transform: translateY(-8px);
}

.hint--always.hint--top:after {
  transform: translateX(-50%)translateY(-8px);
}

.hint--always.hint--top-left:before {
  transform: translateY(-8px);
}

.hint--always.hint--top-left:after {
  transform: translateX(-100%)translateY(-8px);
}

.hint--always.hint--top-right:before, .hint--always.hint--top-right:after {
  transform: translateY(-8px);
}

.hint--always.hint--bottom:before {
  transform: translateY(8px);
}

.hint--always.hint--bottom:after {
  transform: translateX(-50%)translateY(8px);
}

.hint--always.hint--bottom-left:before {
  transform: translateY(8px);
}

.hint--always.hint--bottom-left:after {
  transform: translateX(-100%)translateY(8px);
}

.hint--always.hint--bottom-right:before, .hint--always.hint--bottom-right:after {
  transform: translateY(8px);
}

.hint--always.hint--left:before, .hint--always.hint--left:after {
  transform: translateX(-8px);
}

.hint--always.hint--right:before, .hint--always.hint--right:after {
  transform: translateX(8px);
}

.hint--rounded:after {
  border-radius: 4px;
}

.hint--no-animate:before, .hint--no-animate:after {
  transition-duration: 0s;
}

.hint--bounce:before, .hint--bounce:after {
  -webkit-transition: opacity .3s, visibility .3s, -webkit-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
  -moz-transition: opacity .3s, visibility .3s, -moz-transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
  transition: opacity .3s, visibility .3s, transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
}

.cssload-loader {
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  perspective: 140px;
  float: left;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  margin-top: 4px;
  margin-right: 6px;
  position: relative;
}

.cssload-inner {
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
}

.cssload-inner.cssload-one {
  -ms-animation: cssload-rotate-one 1.15s linear infinite;
  border-bottom: 1px solid #000;
  animation: 1.15s linear infinite cssload-rotate-one;
  top: 0%;
  left: 0%;
}

.cssload-inner.cssload-two {
  -ms-animation: cssload-rotate-two 1.15s linear infinite;
  border-right: 1px solid #000;
  animation: 1.15s linear infinite cssload-rotate-two;
  top: 0%;
  right: 0%;
}

.cssload-inner.cssload-three {
  -ms-animation: cssload-rotate-three 1.15s linear infinite;
  border-top: 1px solid #000;
  animation: 1.15s linear infinite cssload-rotate-three;
  bottom: 0%;
  right: 0%;
}

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg)rotateY(-45deg)rotateZ(0);
  }

  100% {
    transform: rotateX(35deg)rotateY(-45deg)rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-one {
  0% {
    -o-transform: rotateX(35deg)rotateY(-45deg)rotateZ(0);
  }

  100% {
    -o-transform: rotateX(35deg)rotateY(-45deg)rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg)rotateY(-45deg)rotateZ(0);
  }

  100% {
    -webkit-transform: rotateX(35deg)rotateY(-45deg)rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-one {
  0% {
    -moz-transform: rotateX(35deg)rotateY(-45deg)rotateZ(0);
  }

  100% {
    -moz-transform: rotateX(35deg)rotateY(-45deg)rotateZ(360deg);
  }
}

@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg)rotateY(10deg)rotateZ(0);
  }

  100% {
    transform: rotateX(50deg)rotateY(10deg)rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-two {
  0% {
    -o-transform: rotateX(50deg)rotateY(10deg)rotateZ(0);
  }

  100% {
    -o-transform: rotateX(50deg)rotateY(10deg)rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg)rotateY(10deg)rotateZ(0);
  }

  100% {
    -webkit-transform: rotateX(50deg)rotateY(10deg)rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-two {
  0% {
    -moz-transform: rotateX(50deg)rotateY(10deg)rotateZ(0);
  }

  100% {
    -moz-transform: rotateX(50deg)rotateY(10deg)rotateZ(360deg);
  }
}

@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg)rotateY(55deg)rotateZ(0);
  }

  100% {
    transform: rotateX(35deg)rotateY(55deg)rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-three {
  0% {
    -o-transform: rotateX(35deg)rotateY(55deg)rotateZ(0);
  }

  100% {
    -o-transform: rotateX(35deg)rotateY(55deg)rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg)rotateY(55deg)rotateZ(0);
  }

  100% {
    -webkit-transform: rotateX(35deg)rotateY(55deg)rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-three {
  0% {
    -moz-transform: rotateX(35deg)rotateY(55deg)rotateZ(0);
  }

  100% {
    -moz-transform: rotateX(35deg)rotateY(55deg)rotateZ(360deg);
  }
}

.modal {
  z-index: 999999;
}

.Select {
  position: relative;
}

.Select, .Select div, .Select input, .Select span {
  box-sizing: border-box;
}

.Select.is-disabled > .Select-control {
  background-color: #f9f9f9;
}

.Select.is-disabled > .Select-control:hover {
  box-shadow: none;
}

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
}

.Select-control {
  color: #333;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 36px;
  display: table;
  position: relative;
  overflow: hidden;
}

.Select-control:hover {
  box-shadow: 0 1px #0000000f;
}

.is-searchable.is-open > .Select-control {
  cursor: text;
}

.is-open > .Select-control {
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.is-open > .Select-control > .Select-arrow {
  border-width: 0 5px 5px;
  border-color: #0000 #0000 #999;
}

.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text;
}

.is-focused:not(.is-open) > .Select-control {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px #00000013, 0 0 0 3px #007eff1a;
}

.Select-placeholder, :not(.Select--multi) > .Select-control .Select-value {
  color: #aaa;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 34px;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.has-value:not(.Select--multi) > .Select-control > .Select-value .Select-value-label, .has-value.is-pseudo-focused:not(.Select--multi) > .Select-control > .Select-value .Select-value-label {
  color: #333;
}

.has-value:not(.Select--multi) > .Select-control > .Select-value a.Select-value-label, .has-value.is-pseudo-focused:not(.Select--multi) > .Select-control > .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none;
}

.has-value:not(.Select--multi) > .Select-control > .Select-value a.Select-value-label:hover, .has-value.is-pseudo-focused:not(.Select--multi) > .Select-control > .Select-value a.Select-value-label:hover, .has-value:not(.Select--multi) > .Select-control > .Select-value a.Select-value-label:focus, .has-value.is-pseudo-focused:not(.Select--multi) > .Select-control > .Select-value a.Select-value-label:focus {
  color: #007eff;
  outline: none;
  text-decoration: underline;
}

.Select-input {
  vertical-align: middle;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
}

.Select-input > input {
  box-shadow: none;
  cursor: default;
  font-family: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: none;
  height: 34px;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.is-focused .Select-input > input {
  cursor: text;
}

.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}

.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}

.Select-loading-zone {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  display: table-cell;
  position: relative;
}

.Select-loading {
  box-sizing: border-box;
  vertical-align: middle;
  border: 2px solid #ccc;
  border-right-color: #333;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: .4s linear infinite Select-animation-spin;
  display: inline-block;
  position: relative;
}

.Select-clear-zone {
  color: #999;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 17px;
  animation: .2s Select-animation-fadeIn;
  display: table-cell;
  position: relative;
}

.Select-clear-zone:hover {
  color: #d0021b;
}

.Select-clear {
  font-size: 18px;
  line-height: 1;
  display: inline-block;
}

.Select--multi .Select-clear-zone {
  width: 17px;
}

.Select-arrow-zone {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px;
  display: table-cell;
  position: relative;
}

.Select-arrow {
  border: 5px solid #0000;
  border-top-color: #999;
  border-bottom-width: 2.5px;
  width: 0;
  height: 0;
  display: inline-block;
}

.is-open .Select-arrow, .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #666;
}

@keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Select-menu-outer {
  box-sizing: border-box;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;
  max-height: 200px;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  box-shadow: 0 1px #0000000f;
}

.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}

.Select-option {
  box-sizing: border-box;
  color: #666;
  cursor: pointer;
  background-color: #fff;
  padding: 8px 10px;
  display: block;
}

.Select-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.Select-option.is-focused {
  color: #333;
  background-color: #007eff14;
}

.Select-option.is-disabled {
  color: #ccc;
  cursor: default;
}

.Select-noresults {
  box-sizing: border-box;
  color: #999;
  cursor: default;
  padding: 8px 10px;
  display: block;
}

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0;
}

.Select--multi.has-value .Select-input {
  margin-left: 5px;
}

.Select--multi .Select-value {
  color: #007eff;
  vertical-align: top;
  background-color: #007eff14;
  border: 1px solid #007eff3d;
  border-radius: 2px;
  margin-top: 5px;
  margin-left: 5px;
  font-size: .9em;
  line-height: 1.4;
  display: inline-block;
}

.Select--multi .Select-value-icon, .Select--multi .Select-value-label {
  vertical-align: middle;
  display: inline-block;
}

.Select--multi .Select-value-label {
  cursor: default;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 2px 5px;
}

.Select--multi a.Select-value-label {
  color: #007eff;
  cursor: pointer;
  text-decoration: none;
}

.Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-right: 1px solid #007eff3d;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 1px 5px 3px;
}

.Select--multi .Select-value-icon:hover, .Select--multi .Select-value-icon:focus {
  color: #0071e6;
  background-color: #0071e614;
}

.Select--multi .Select-value-icon:active {
  background-color: #007eff3d;
}

.Select--multi.is-disabled .Select-value {
  color: #333;
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
}

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3;
}

.Select--multi.is-disabled .Select-value-icon:hover, .Select--multi.is-disabled .Select-value-icon:focus, .Select--multi.is-disabled .Select-value-icon:active {
  background-color: #fcfcfc;
}

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}
/*# sourceMappingURL=index.404be5f6.css.map */
